<script>
export default {
  props: {
    banco: { type: Object }
  },
}
</script>

<template>
<div id="banco">
  <p>Nome: {{ banco.banco_nome }}</p>
  <p>Codigo do Banco: {{ banco.banco_codigo}} </p>
  <p>Digito: {{ banco.banco_digito}} </p>
  <p>Numero: {{ banco.banco_maxnossoNum}} </p>
  <p>Orientações: {{ banco.banco_orientacoes}} </p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ banco.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ banco.updated_at }}</b-badge></p>
</div>
</template>
