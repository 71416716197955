<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";

export default {
  props: { 
      oldBanco: { type: Object, required: true }
    },
 // components: { Multiselect },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Alterar Banco',
      banco: {},
      
    }
  },
  validations: {
    banco: {
        banco_nome : {required},
        banco_codigo: {required},
    }
  },
  mounted() {
    this.setNewTitle();
    this.banco = this.oldBanco;
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPut() {
      console.log('Gravou');
      console.log(this.banco)
      this.$emit('doPut', this.banco);
    },
  },
    
}
</script>

<template>

<div class="card">
<div class="card-body">
    <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
    <form id="form-tipo-movimento" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <h4>Banco</h4>

          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_nome_id" class="col-form-label">id</label>
                  <div class="col">
                    <input disabled v-model="banco.id" class="form-control text-leftt" type="text" placeholder="digite o Nome do banco" id="banco_id">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_nome_id" class="col-form-label">Banco</label>
                  <div class="col">
                    <input v-model="banco.banco_nome" class="form-control text-leftt" type="text" placeholder="digite o Nome do banco" id="banco">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_codigo" class="col-form-label">Codigo</label>
                  <div class="col">
                    <input  v-model="banco.banco_codigo" class="form-control text-left" type="text" placeholder="digite o codigo do banco" id="codigo">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_id" class="col-form-label">Digito</label>
                  <div class="col">
                    <input  v-model="banco.banco_digito" class="form-control text-left" type="text" placeholder="digite o digito" id="digito">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_id" class="col-form-label">Numero Maximo</label>
                  <div class="col">
                    <input  v-model="banco.banco_maxnossoNum" class="form-control text-left" type="text" placeholder="digite o Numero maximo" id="banco">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_id" class="col-form-label">Orientações</label>
                  <div class="col">
                    <input  v-model="banco.banco_orientacoes" class="form-control text-left" type="text" placeholder="Orientações" id="banco">
                  </div>
                </div>
              </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  
  <div class="card-body">
    <div class="col-md-12 mt-10">
      <button class="btn btn-light"
       @click="setPut">Alterar</button>
    </div>
  </div>
</div>
</template>