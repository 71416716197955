<script>
import appConfig from "@/app.config";
import { http } from '@/helpers/easyindustriaapi/config';
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { listCFOPs } from "../../../../components/defaults/cfops";

export default {
  page: {
    title: "Produto Vendas",
    meta: [{name: "description", content: appConfig.description}]
  },
  props: {
    currentEmpresa: { type: Object },
    newPedido: { type: Object },
    listTabelas: { type: Object },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      titleBody: "Produto Vendas",
      submitted: false,
      pedido: {},
      listFornecedores: [
        "10023",
        "23123",
        "10023",
        "23123",
        "10023",
        "23123",
      ],
      listTiposPagamento: [
        {
          id: 0,
          name: 'Dinheiro',
        }
      ],
      CFOPs: [],
      listHistoricosPadroes: [],
      listTpsMovimentos: [],
      listLocaisEstoque: [],
      fornecedorSelecionado: null,
      tipoPagamentoSelecionado: null,
      cfopSelecionado: null,
      historicoPdrSelecionado: null,
      tpMovimentoSelecionado: null,
      estoqueOrigemSelecionado: null,
      estoqueDestinoSelecionado: null,
      codBBar: null,
      selecionado: null, // converte de array para variavel;
      CFOP: [
        "1324",
        "1231",
        "1233"
      ],
      quantidade:null,
      preco: null,
      subTotal: null,
      desconto: null,
      Porcentagem:null


    }
  },
  validations() {
    return {
      pedido: {
        // tpnf: { required },
        nnf: { required },
        pessoa_id: { required },
        tipopgto_id: { required },
        qtde_parcelas: { required },
        historicopdr_id: { required },
        tpmovimento_id: { required },
        loc_id: { required },
      }
    }
  },
  mounted() {
    this.setNewTitle();
    this.pedido = this.newPedido;

    if (this.pedido.id)
      this.getData();

    var fornecedores = this.listTabelas.pessoas;
    this.CFOPs = listCFOPs;
    var tiposPagamento = this.listTabelas.tiposPagamento;
    var historicosPadroes = this.listTabelas.historicosPadroes;
    var tpsMovimentos = this.listTabelas.tpsMovimentos;
    var locaisEstoque = this.listTabelas.locaisEstoque;
    this.listFornecedores = fornecedores.map((item) => {
      return {
        name: item.pes_apelido,
        id: item.id,
        uf: item.pes_uf
      };
    });
    this.listTiposPagamento = tiposPagamento.map((item) => {
      return {
        name: item.tiponome,
        id: item.id
      };
    });
    this.listHistoricosPadroes = historicosPadroes.map((item) => {
      return {
        name: item.descricao,
        id: item.id
      };
    });
    this.listTpsMovimentos = tpsMovimentos.map((item) => {
      return {
        name: item.nometpmov,
        id: item.id,
        cfop: item.cfop,
        serie: item.serie,
      };
    });
    this.listLocaisEstoque = locaisEstoque.map((item) => {
      return {
        name: item.descricao,
        id: item.id,
        ind_est: item.ind_est,
      };
    });
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    getData() {
      this.$emit('onLoader');
      http.get('/pedido/' + this.pedido.id + '/item');
      this.$emit('offLoader');
    },
    selectFornecedor(value) {
      this.pedido.pessoa_id = value ? value.id : null;
    },
    selectTipoPagamento(value) {
      this.pedido.tipopgto_id = value ? value.id : null;
    },
    selectCFOP(value) {
      this.pedido.cfop = value ? value.id : null;
    },
    selectHistoricoPdr(value) {
      this.pedido.historicopdr_id = value ? value.id : null;
    },
    selectTpMovimento(value) {
      this.pedido.tpmovimento_id = value ? value.id : null;
      var cfop = null;
      if (value) {
        if (this.currentEmpresa.uf === this.fornecedorSelecionado.uf) { 
          cfop = value.cfop.replace('*', '1').replace('.', '');
        } else {
          cfop = value.cfop.replace('*', '2').replace('.', '');
        }

        this.pedido.serie = value.serie;
        this.pedido.cfop = cfop;
        this.CFOPs.filter(item => {
          if (item.id === parseInt(cfop)) {
            this.cfopSelecionado = item;
          }
        });
      }
    },
    selectEstoqueOrigem(value) {
      this.pedido.loc_origem_id = value ? value.id : null;
      if (value) {
        this.listLocaisEstoque.filter(item => {
          if (item.id === value.id) {
            this.estoqueOrigemSelecionado = item;
          }
        });
      }
    },
    selectEstoqueDestino(value) {
      this.pedido.loc_id = value ? value.id : null;

      if (value) {
        this.listLocaisEstoque.filter(item => {
          if (item.id === value.id) {
            this.estoqueDestinoSelecionado = item;
          }
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    formPedido(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.pedido.$error)
      {
        this.makeToast('danger', 'Erro de validação!');
      } else {
        this.$emit("doPost", this.pedido);
      }
    }
  },
}
</script>

<template>
  <div class="card">
    <div class="accordion" role="tablist">
      <form role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-dados-gerais variant="light">Produtos Vendas</b-button>
          </b-card-header>
          <b-collapse id="pedido-dados-gerais" visible accordion="pedido-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-row form-group" role="group">
                
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="pedido_id" class="col-form-label">Código Produto</label>
                    <div class="col">
                      <input disabled v-model="codBBar" class="form-control text-right" type="text"
                        placeholder="Gerado pelo sistema" id="pedido_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="pessoa_id" class="col-form-label">Descricao</label>
                    <div class="col">
                      <multiselect v-model="fornecedorSelecionado" :options="listFornecedores" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" label="name"
                        @input="selectFornecedor">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{ option.name}}</strong>
                        </template>
                      </multiselect>
                      <b-form-invalid-feedback v-if="submitted && $v.pedido.pessoa_id.$error" :state="($v.pedido.pessoa_id.required)
                      ">
                        {{ $t('mesages.validation.selectone') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="CFOP_id" class="col-form-label">CFOP</label>
                    <div class="col">
                      <multiselect v-model="cfopSelecionado" :options="CFOP" placeholder="Selecione"
                        track-by="id" deselect-label="" 
                        @input="selectFornecedor">
                        
                      </multiselect>
                      <!--<b-form-invalid-feedback v-if="submitted && $v.pedido.pessoa_id.$error" :state="($v.pedido.pessoa_id.required)
                      ">
                        {{ $t('mesages.validation.selectone') }}
                      </b-form-invalid-feedback> -->
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="descricao_id" class="col-form-label">Descrição</label>
                    <div class="col">
                      <input  v-model="selecionado" class="form-control text-right" type="text"
                        placeholder="Gerado pelo sistema" id="pedido_id">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="quatidade_id" class="col-form-label">Quantidade</label>
                    <div class="col">
                      <input v-model="quantidade" type="text" class="form-control text-right" placeholder="1..9" id="quantidade"
                       :class="{ 'is-invalid': submitted && $v.pedido.nnf.$error }">
                      <div v-if="submitted && $v.pedido.nnf.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.nnf.required"> {{ $t('mesages.validation.required') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                    <label for="preco" class="col-form-label">Preço</label>
                    <div class="col">
                      <input v-model="preco" type="text" class="form-control text-right" placeholder="1.9" id="preco_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                    <label for="sub" class="col-form-label">Sub Total</label>
                    <div class="col">
                      <input v-model="subTotal" type="text" class="form-control text-right" placeholder="1.9" id="sub_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                    <label for="desconto" class="col-form-label">Desconto</label>
                    <div class="col">
                      <input v-model="desconto" type="text" class="form-control text-right" placeholder="ddigite" id="desc_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="porcent" class="col-form-label">Porcentagem</label>
                    <div class="col">
                      <multiselect v-model="cfopSelecionado" :options="CFOPs" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" select-label="Pressione ENTER"
                        label="id" @input="selectCFOP">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{option.id}}-{{option.name}}</strong>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
         </b-collapse>
         <!-- Menus de Impostos-->
         
          
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-complementares variant="light">ICMS</b-button>
          </b-card-header>
          <b-collapse id="pedido-complementares">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="regime" class="col-form-label">Regime</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="regime_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="CSOSN" class="col-form-label">CSOSN</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="CSOSN">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="Origem" class="col-form-label">Origem</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="origem_id">
                    </div>
                    <div id="espaco">
                    <p>&nbsp;</p> 
                    </div>
                    
                    <label for="BC_ICMS" class="col-form-label">Modalidade Determ. de BC ICMS</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="BC_ICMS">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="base_icms" class="col-form-label">Base ICMS</label>
                    <div class="col">
                      <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="icms_id">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="chnfe" class="col-form-label">Aliquota</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="chnfe">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="chnfe" class="col-form-label">Redução de BC</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="chnfe">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="v_icms" class="col-form-label">Valor ICMS</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="v_icms_id">
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>

          <!--- ------------ -->
          
          

          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-ipi variant="light2">IPI</b-button>
          </b-card-header>
          <b-collapse id="pdv-ipi">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="s_tributaria" class="col-form-label">Situação Tributaria</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="trib_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="cod_enq" class="col-form-label">Codigo de Enquadramento</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="cod_enq_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    
                    <div id="espaco1">
                    <p>Valor IPI</p> 
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="base_ipi" class="col-form-label">Base IPI R$:</label>
                    <div class="col">
                      <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="b_ipi_id">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="valor_ip" class="col-form-label">Valor do IP %:</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="v_ip_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="aliquota" class="col-form-label">Aliquota do Ipi:</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="Aliq_id">
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>


          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-pis variant="light">PIS</b-button>
          </b-card-header>

          <b-collapse id="pdv-pis">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="sit_trib" class="col-form-label">Situação Tributaria</label>
                    <div class="col">
                      <multiselect v-model="cfopSelecionado" :options="CFOPs" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" select-label="Pressione ENTER"
                        label="id" @input="selectCFOP">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{option.id}}-{{option.name}}</strong>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>

          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-cofins variant="light">COFINS</b-button>
          </b-card-header>

          <b-collapse id="pdv-cofins">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="sit_trib" class="col-form-label">Situação Tributaria</label>
                    <div class="col">
                      <multiselect v-model="cfopSelecionado" :options="CFOPs" placeholder="Selecione"
                        track-by="id" deselect-label="Pressione ENTER para remover" select-label="Pressione ENTER"
                        label="id" @input="selectCFOP">
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>{{option.id}}-{{option.name}}</strong>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>

          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-info-ad variant="light">Informações Adicionais</b-button>
          </b-card-header>

          <b-collapse id="pdv-info-ad">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="sit_tributaria" class="col-form-label">Situação Tributaria</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="chnfe">
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>

          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-icms-int variant="light">ICMS Interestadual</b-button>
          </b-card-header>

          <b-collapse id="pdv-icms-int">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="bc_destino" class="col-form-label">BC do FCP na UF destino</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="bc_destino_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="fcp_dest" class="col-form-label">%ICMS FCP UF destino</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="fcp_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="v_icms_fcp" class="col-form-label">Valor ICMS FCP na UF destino</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="v_cfp_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="v_uf_bc_icms" class="col-form-label">BC do ICMS da UF de Destino</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="bc_icms">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="alq_int" class="col-form-label">Aliquota interestadual</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="alq_int_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="p_p_icms" class="col-form-label">% provisório de partilha de ICMS*</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="p_p_icms_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="ali_int_uf" class="col-form-label">Aliquota interna da UF destino</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="ali_int_uf_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="v_icms_int_dest" class="col-form-label">Valor ICMS interestadual para UF destino</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="v_icms_int_dest_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="v_icms_int_rem" class="col-form-label">Valor ICMS interestadual para UF remetente</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="v_icms_int_rem_id">
                    </div>
                  </div>
                </div>
               </div>
                   <!-- linha de baixo-->
               
              </b-card-body>
            </b-collapse>

          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-pc variant="light">Pedido de Compra</b-button>
          </b-card-header>

          <b-collapse id="pdv-pc">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="sit_trib" class="col-form-label">Situação Tributaria</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="sit_trib_id">
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          
        </b-card> <!-- engloba  todos-->
      </form> <!-- form engloba todos-->
    </div> <!--Engloba todos -->


    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="formPedido">Gravar Iten pedido</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
#espaco{
  margin-top: 60px;
}
#espaco1{
  margin-top: 40px;
  font-weight: bold;
}
</style>